import React from 'react';

import { Wrapper, Block } from '../../../components/order/styledComponents';
import Availabilities from '../../../components/order/Availabilities';
import OrderContainer from '../../common/OrderContainer';
import ProgressBar from '../../../components/order/ProgressBar';

class RdvContainer extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <OrderContainer>
        <Wrapper>
          <Block>
            <ProgressBar step={2} />

            <Availabilities />
          </Block>
        </Wrapper>
      </OrderContainer>
    );
  }
}
export default RdvContainer;
